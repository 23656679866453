import React from "react"

import Layout from "../components/layout"

const IndexPage = () => (
    <Layout>
        <div className='flex justify-center align-center mt-12 text-5xl font-bold text-charcoal-600 tracking-wide'>
            About
        </div>

        <div className='text-charcoal-600 p-3 md:p-0 w-screen md:w-162 flex flex-col justify-center items-start align-center' >

            <div className='md:flex mt-16'>
                <div className='flex mt-4 md:hidden justify-center items-center'>
                    <div className='w-36'>
                        <img src={'/gg.jpg'} alt="Grzegorz Gralak" />
                    </div>
                </div>
                <div className=' pr-12  inline-block'>
                    <div className='mt-0'>Hello! I'm Grzegorz Gralak, that's me behind the teamwide.com. I'm a software developer who likes to explore how teams work. </div>
                    <div className='mt-4'>Plan is that on teamwide.com you will find:</div>
                    <ul className='list-disc ml-8'>
                        <li>in depth articles on topics close to software teams</li>
                        <li>free tools which will help your team a bit</li>
                    </ul>

                    <div className='mt-4'>I also write a monthly Team Talk newsletter with most interesting articles I read recently about software teams.</div>

                </div>
                <div className='hidden md:flex w-36 md:w-auto justify-center items-center'>
                    <img src={'/gg.jpg'} alt="Grzegorz Gralak" />
                </div>
            </div>

        </div>
    </Layout>
);

export default IndexPage